import { Button, Container } from '@the-headless-club/components'
import { styled } from '@the-headless-club/styles'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePagination } from './helpers/usePagination'

const Wrap = styled(Container, {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '$8',
  paddingBottom: '$12',
})
const PageButton = styled(Button, {
  padding: '0 $8',
  projectFont: 'body04',
  color: '$almost_black',
  whiteSpace: 'nowrap',
  height: 22,
  variants: {
    selected: {
      true: {
        border: '1px solid',
        borderRadius: '50%',
      },
    },
  },
})

type PaginationProps = {
  totalCount?: number
  start?: number
  limit?: number
}
export const Pagination: FC<PaginationProps> = ({ totalCount, limit }) => {
  const [showMorePages, setShowMorePages] = useState(false)
  const pages = totalCount && limit ? Math.ceil(totalCount / limit) : 0
  const pagesArray = [...Array(pages)].map((el, index) => index + 1)
  const VISIBLE_DEFAULT_PAGES = 3
  const [currentPage, setCurrentPage] = usePagination()
  const { t } = useTranslation('sites')
  return (
    <Wrap>
      {pagesArray.length > 1 &&
        pagesArray.slice(0, VISIBLE_DEFAULT_PAGES).map((el) => (
          <PageButton
            appearance="grid"
            key={`page-${el}`}
            selected={+currentPage === el}
            onClick={() => {
              setCurrentPage(el)
            }}
          >
            {el}
          </PageButton>
        ))}
      {showMorePages || +currentPage >= VISIBLE_DEFAULT_PAGES
        ? pagesArray.slice(VISIBLE_DEFAULT_PAGES).map((el) => (
            <PageButton
              appearance="grid"
              key={`page-${el}`}
              selected={+currentPage === el}
              onClick={() => {
                setCurrentPage(el)
              }}
            >
              {el}
            </PageButton>
          ))
        : pages > VISIBLE_DEFAULT_PAGES && (
            <PageButton
              appearance="grid"
              onClick={() => {
                setShowMorePages(true)
              }}
            >
              ...
            </PageButton>
          )}
      {+currentPage !== pages && pagesArray.length > 1 && (
        <PageButton
          appearance="grid"
          onClick={() => {
            if (+currentPage !== pages) {
              setCurrentPage(+currentPage + 1)
            }
          }}
        >
          {t('nextPage')}
        </PageButton>
      )}
    </Wrap>
  )
}
