import { FC, useRef } from 'react'
import { styled } from '@the-headless-club/styles'
import { Filter } from '@the-headless-club/models'
import { useRouter } from 'next/dist/client/router'

const FormWrapper = styled('form', {
  display: 'grid',
  projectFont: 'body01',
  cursor: 'pointer',
})

const Label = styled('label', {
  display: 'grid',
  gridAutoFlow: 'column',
  cursor: 'pointer',
  alignItems: 'center',
  gap: '$5',
  '&:hover': {
    opacity: '0.5',
  },
})

const Input = styled('input', {
  display: 'grid',
  gridAutoFlow: 'column',
  width: '100%',
  height: '100%',
  gridRowStart: 1,
  gridColumnStart: 1,
  gridColumnEnd: 3,
  background: 'transparent',
  margin: 0,
  '-webkit-appearance': 'none',
  appearance: 'none',
  cursor: 'pointer',
  '&:focus-visible': {
    outline: '2px solid $black',
    outlineOffset: '2px',
  },
})

const VisibleInput = styled('div', {
  display: 'grid',
  projectFont: 'body01',
  gap: '$5',
  gridAutoFlow: 'column',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  gridRowStart: 1,
  gridColumnStart: 1,
  paddingLeft: 34,
})

const Switch = styled('div', {
  display: 'grid',
  gridRowStart: 1,
  gridColumnStart: 2,
  width: 24,
  height: 13,
  borderRadius: '$r3',
  border: '1px solid $almost_black',
  position: 'relative',

  '&:after': {
    content: '',
    width: 9,
    height: 9,
    borderRadius: '$rMax',
    background: '$almost_black',
    top: 1,
    left: 1,
    position: 'absolute',
    transition: 'all 100ms ease-in',
  },

  variants: {
    isActive: {
      true: {
        '&:after': {
          transform: 'translateX(11px)',
          background: 'linear-gradient(to right, #53F0C9, #7FF392)',
        },
      },
    },
  },
})

interface FilterPerformanceToggleProps {
  filter: Filter
  withPagination?: boolean
}

const PerformanceBolt: FC = () => {
  return (
    <svg
      width="11"
      height="15"
      viewBox="0 0 16 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 12.5H7.39062L4.99531 21.5L15.5 9.5H8.60938L11 0.5L0.5 12.5Z"
        fill="url(#paint0_linear_541_107)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_541_107"
          x1="8"
          y1="0.5"
          x2="8"
          y2="21.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#89F384" />
          <stop offset="1" stopColor="#3DEEE3" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const FilterPerformanceToggle: FC<FilterPerformanceToggleProps> = ({
  filter,
}) => {
  const formRef = useRef<HTMLFormElement>(null)
  const { query, replace } = useRouter()

  const filterValue = filter.values[0]

  if (!filterValue) {
    return null
  }

  const onFormSubmit = () => {
    const form = formRef.current || undefined
    const data = new FormData(form)

    const { page, ...strippedQuery } = query

    const checkValue =
      data.get(filter.name) === 'on' ? filterValue.name : undefined

    if (!checkValue) {
      delete strippedQuery[filter.name]
    }

    replace(
      {
        query: {
          ...strippedQuery,
          ...(checkValue && {
            [filter.name]: checkValue,
          }),
        },
      },
      undefined,
      {
        shallow: true,
      },
    )
  }

  const isChecked = query[filter.name] === filterValue.name

  return (
    <FormWrapper ref={formRef}>
      <Label>
        <VisibleInput>
          <PerformanceBolt />
          {filterValue.name}
        </VisibleInput>
        <Input
          name={filter.name}
          type="checkbox"
          checked={isChecked}
          onChange={onFormSubmit}
        />
        <Switch isActive={isChecked} />
      </Label>
    </FormWrapper>
  )
}
