import { FC } from 'react'
import { SectionSiteDetailsProps } from '@the-headless-club/models'
import { styled } from '@the-headless-club/styles'
import {
  Container,
  Link,
  Media,
  PerformanceMetricsDetails,
} from '@the-headless-club/components'
import { useTranslation } from 'react-i18next'
import { GoToArrow } from '@the-headless-club/icons'

const Wrap = styled('div', {
  background: '$alabaster',
  padding: '$11 0',
  '@lg': {
    padding: '$12 0',
  },
})
const StyledContainer = styled(Container, {
  display: 'grid',
  position: 'relative',
  alignItems: 'flex-start',
  gap: '$10',
  '@lg': {
    gap: '$12',
    gridTemplateColumns: '65% 1fr',
  },
  '@xl': {
    gap: '$14',
  },
})
const ImageOpenButton = styled(Link, {
  position: 'absolute',
  right: '$8',
  bottom: '$8',
  opacity: 0,
  visibility: 'hidden',
  color: '$white',
  background: '$black_02',
  zIndex: '$header',
  '&:hover': {
    background: '$black_04',
  },
})
const GalleryWrap = styled('div', {
  display: 'grid',
  '@lg': {
    position: 'sticky',
    top: 84,
    left: 0,
  },
  '&:hover': {
    [`& ${ImageOpenButton}`]: {
      '@lg': {
        opacity: 1,
        visibility: 'visible',
      },
    },
  },
})
const Gallery = styled(Link, {
  display: 'grid',
  borderRadius: '$r2',
  overflow: 'hidden',
  background: '$alabaster_01',
})
const Info = styled('div', {
  display: 'grid',
  gap: '$10',
})
const Title = styled('h1', {
  projectFont: 'heading01',
})
const Tags = styled('div', {
  display: 'flex',
})

const TitleSmall = styled('div', {
  color: '$almost_black',
  projectFont: 'caps01',
})

const ToolsWrap = styled('div', {
  paddingTop: '$11',
  display: 'grid',
  gap: '$9',
})
const ToolsInner = styled('div', {
  display: 'grid',
  gap: '$4',
})

const ToolCard = styled(Link, {
  background: '$black_005',
  borderRadius: '$r2',
  overflow: 'hidden',
  gridTemplateColumns: '56px 1fr',
  alignItems: 'center',
  gap: '$8',
  '@lg': {
    '&:hover': {
      background: '$black_002',
    },
  },
})
const ToolImage = styled('div', {})

const ToolInfo = styled('div', {
  display: 'grid',
  gap: '$3',
})
const ToolName = styled('p', {
  color: '$almost_black',
  projectFont: 'body04',
})

const ToolCategory = styled('div', {
  projectFont: 'body02',
  color: '$almost_black',
})
const TitleWrap = styled('div', {
  display: 'grid',
  gap: '$8',
  justifySelf: 'start',
  alignItems: 'center',
  gridAutoFlow: 'column',
  paddingBottom: '$10',
})

const OpenButton = styled(Link, {
  color: '$black',
  opacity: 1,
  visibility: 'visible',
})

export const SectionSiteDetails: FC<SectionSiteDetailsProps> = ({
  desktopMediaList,
  name,
  tags,
  url,
  ECPlatform,
  FEFramework,
  CMS,
  tools,
  perfMetric,
  highPerformance,
}) => {
  const { t } = useTranslation('site')

  return (
    <Wrap>
      <StyledContainer>
        <GalleryWrap>
          <Gallery href={url} target="_blank">
            {desktopMediaList && desktopMediaList[0] && (
              <Media
                {...desktopMediaList[0]}
                hardcropRatio="landscape2"
                sizes="(min-width: 1000px) 65vw, 100vw"
                hardHotspot={[0, 0]}
              />
            )}
          </Gallery>
          <ImageOpenButton href={url} appearance="ellipse" target="_blank">
            {t('open')} <GoToArrow fill="white" />
          </ImageOpenButton>
        </GalleryWrap>
        <Info>
          <Tags>
            {tags?.map((tag, index) => (
              <Link
                key={`${tag.name}-${index}`}
                appearance="containedWhiteRect"
                href={tag.path}
              >
                {tag.name}
              </Link>
            ))}
          </Tags>
          <TitleWrap>
            {name && <Title>{name}</Title>}
            <OpenButton href={url} appearance="ellipse" target="_blank">
              {t('open')} <GoToArrow />
            </OpenButton>
          </TitleWrap>

          {perfMetric?.mobile && (
            <PerformanceMetricsDetails
              perfMetric={perfMetric.mobile}
              date={perfMetric.updatedAt ?? ''}
              highPerformance={highPerformance}
            />
          )}

          {(ECPlatform || FEFramework || CMS) && (
            <ToolsWrap>
              <TitleSmall>{t('tools')}</TitleSmall>
              <ToolsInner>
                {ECPlatform?.path && (
                  <ToolCard href={ECPlatform.path} appearance="grid">
                    <ToolImage>
                      {ECPlatform.avatar && (
                        <Media sizes="56px" {...ECPlatform.avatar} />
                      )}
                    </ToolImage>
                    <ToolInfo>
                      <ToolName>{ECPlatform.name}</ToolName>
                      <ToolCategory>{t('ECPlatform')}</ToolCategory>
                    </ToolInfo>
                  </ToolCard>
                )}

                {FEFramework?.path && (
                  <ToolCard href={FEFramework.path} appearance="grid">
                    <ToolImage>
                      {FEFramework.avatar && <Media {...FEFramework.avatar} />}
                    </ToolImage>
                    <ToolInfo>
                      <ToolName>{FEFramework.name}</ToolName>
                      <ToolCategory>{t('FEFramework')}</ToolCategory>
                    </ToolInfo>
                  </ToolCard>
                )}
                {CMS?.path && (
                  <ToolCard href={CMS.path} appearance="grid">
                    <ToolImage>
                      {CMS.avatar && <Media {...CMS.avatar} />}
                    </ToolImage>
                    <ToolInfo>
                      <ToolName>{CMS.name}</ToolName>
                      <ToolCategory>{t('CMS')}</ToolCategory>
                    </ToolInfo>
                  </ToolCard>
                )}
              </ToolsInner>
            </ToolsWrap>
          )}
          {tools && tools?.length > 0 && (
            <ToolsWrap>
              <TitleSmall>{t('otherTools')}</TitleSmall>
              <ToolsInner>
                {tools?.map((tool, index) => (
                  <ToolCard
                    key={`${tool.name}-${index}`}
                    href={tool.path}
                    appearance="grid"
                  >
                    <ToolImage>
                      {tool.avatar && <Media {...tool.avatar} />}
                    </ToolImage>
                    <ToolInfo>
                      <ToolName>{tool.name}</ToolName>
                      <ToolCategory>{tool.toolCategory?.name}</ToolCategory>
                    </ToolInfo>
                  </ToolCard>
                ))}
              </ToolsInner>
            </ToolsWrap>
          )}
        </Info>
      </StyledContainer>
    </Wrap>
  )
}
