import { FC } from 'react'
import { SectionInfoAboutUsProps } from '@the-headless-club/models'
import { Media, PortableText } from '@the-headless-club/components'
import { styled } from '@the-headless-club/styles'
import { BlueBadge } from '@the-headless-club/icons'

const Wrap = styled('div', {
  containerSpace: 'medium',
  '@md': {
    containerSpace: 'none',
  },
  maxWidth: 640,
  margin: '$13 auto',
  color: '$almost_black',
})
const ItemWrap = styled('div', {
  margin: '$15 0',
})
const CreatorsWrap = styled('div', {
  columnGap: '$11',
  rowGap: '$9',
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',

  '@md': {
    justifyContent: 'center',
  },
})
const Avatar = styled('div', {
  width: 56,
  height: 56,
  borderRadius: '$rMax',
  overflow: 'hidden',
})
const Creator = styled('div', {
  display: 'grid',
  gridTemplateColumns: '56px 1fr',
  gridGap: '$5',
  alignItems: 'center',
})
const CreatorName = styled('p', {
  projectFont: 'body06',
})
const TitleWrap = styled('div', {
  display: 'grid',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '$12',
})
const BadgeWrap = styled('div', {
  gridRow: 1,
  gridColumn: 1,
})
const BadgeText = styled('div', {
  textAlign: 'center',
  projectFont: 'body05',
  gridColumn: 1,
  gridRow: 1,
})
const RichTextWrap = styled('div', {
  marginBottom: '$12',
  '*:last-child': {
    marginBottom: 0,
  },
  '*:first-child': {
    marginTop: 0,
  },
  strong: {
    fontWeight: 'bold',
  },
  a: {
    textDecoration: 'underline',
    textUnderlineOffset: '1px',
    '&:hover': {
      opacity: '0.5',
    },
  },

  h1: {
    projectFont: 'heading03',
    marginBottom: '$9',
    marginTop: '$9',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  h2: {
    projectFont: 'heading01',
    marginBottom: '$9',
    marginTop: '$9',
    fontWeight: 'bold',
  },
  h3: {
    projectFont: 'heading02',
    marginBottom: '$9',
    marginTop: '$9',
    fontWeight: 'bold',
  },

  'p, ul, ol': {
    listStyle: 'inside',
    projectFont: 'body06',
    marginBottom: '$9',
    marginTop: '$9',
  },
  'ol > li': {
    listStyleType: 'decimal',
  },
  'ul > li': {
    listStyleType: 'disc',
  },
})

export const SectionInfoAboutUs: FC<SectionInfoAboutUsProps> = ({ items }) => {
  return items && items?.length > 0 ? (
    <Wrap>
      {items.map((item, index) => (
        <ItemWrap key={`${item.title}-${index}`}>
          {item.title && (
            <TitleWrap>
              <BadgeWrap>
                <BlueBadge />
              </BadgeWrap>
              <BadgeText>{item.title}</BadgeText>
            </TitleWrap>
          )}
          {item.text && (
            <RichTextWrap>
              <PortableText withStyles={false} value={item.text}></PortableText>
            </RichTextWrap>
          )}
          {item.avatars && item.avatars?.length > 0 && (
            <CreatorsWrap>
              {item.avatars.map((avatar, index) => (
                <Creator key={`${avatar.name}-${index}`}>
                  {avatar.avatar && (
                    <Avatar>
                      <Media {...avatar?.avatar} sizes={'56px'} />
                    </Avatar>
                  )}
                  {avatar.name && <CreatorName>{avatar?.name}</CreatorName>}
                </Creator>
              ))}
            </CreatorsWrap>
          )}
        </ItemWrap>
      ))}
    </Wrap>
  ) : (
    <></>
  )
}
