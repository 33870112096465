import { FC } from 'react'
import { SectionToolsLandingPageProps, Tool } from '@the-headless-club/models'
import { Filters } from '../SectionSiteList/Filters'
import { Container, Link, Media, ratios } from '@the-headless-club/components'
import { styled } from '@the-headless-club/styles'
import { Search } from '../SectionSiteList/Search'
import { getTools } from '@the-headless-club/services/sanity-service'
import { useTranslation } from 'react-i18next'

const TitleWrap = styled('div', {
  paddingTop: '$11',
  paddingBottom: '$10',
})
const OtherTools = styled('div', {
  paddingBottom: '$11',
  display: 'grid',
  gap: '$9',
})
const OtherToolsWrap = styled('div', {
  display: 'grid',
  gap: '$9',
  '@lg': {
    gridTemplateColumns: '1fr 1fr',
  },
  '@xl': {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
})
const ToolCardSkeleton = styled('div', {
  background: '$white',
  borderRadius: '$r2',
  overflow: 'hidden',
  gridTemplateColumns: '100px 1fr',
  alignItems: 'center',
  gap: '$8',
  height: 100,
})
const ToolCard = styled(Link, {
  background: '$black_005',
  borderRadius: '$r2',
  overflow: 'hidden',
  gridTemplateColumns: '100px 1fr auto',
  alignItems: 'center',
  gap: '$9',
  height: 100,
})
const ToolImage = styled('div', {
  aspectRatio: ratios.square,
})

const ToolInfo = styled('div', {
  display: 'grid',
  gap: '$6',
  variants: {
    noAvatar: {
      true: {
        gridColumn: '1/3',
        marginLeft: '$9',
      },
    },
  },
})
const ToolCategoryAndUseCaseContainer = styled('div', {
  display: 'grid',
  gap: '$1',
})
const ToolName = styled('p', {
  color: '$almost_black',
  projectFont: 'body05',
  alignItems: 'center',
  gap: '$3',
  overflow: 'hidden',
  display: '-webkit-box',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',
  '@md': {
    '-webkit-line-clamp': 2,
  },
})
const ToolCategory = styled('div', {
  projectFont: 'body01',
  color: '$almost_black',
  overflow: 'hidden',
  display: '-webkit-box',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',
})
const SearchAndFilterContainer = styled('div', {
  display: 'flex',
  overflowX: 'scroll',
  '@lg': {
    containerSpace: 'medium',
    overflow: 'unset',
  },
})
const SearchAndFilterWrap = styled('div', {
  paddingTop: '$10',
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  paddingBottom: '$5',
  paddingLeft: 20,
  containerSpace: 'medium',
  alignItems: 'center',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '@lg': {
    overflow: 'unset',
    containerSpace: 'none',
  },
})

const Divider = styled(Container, {
  height: 1,
  background: '$almost_black',
  marginBottom: '$10',
})

const Title = styled('h2', {
  projectFont: 'heading01',
  textAlign: 'center',
  paddingTop: '$9',
})

const Use = styled('p', {
  projectFont: 'body02',
  marginRight: '$9',
  overflow: 'hidden',
  display: '-webkit-box',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',
  '@md': {
    justifyContent: 'flex-end',
    '-webkit-line-clamp': 2,
    textAlign: 'right',
  },
})

const UseCasesMobile = styled('div', {
  display: 'block',
  '@md': {
    display: 'none',
  },
})

const UseCasesDesktop = styled('div', {
  display: 'none',
  '@md': {
    display: 'block',
  },
})
const NoResults = styled('div', {
  textAlign: 'center',
  gridColumn: '1/4',
  projectFont: 'body04',
  display: 'grid',
  gridAutoFlow: 'row',
  gap: '$8',
  justifyContent: 'center',
})

export const SectionToolsLandingPage: FC<SectionToolsLandingPageProps> = ({
  tools,
  filters,
}) => {
  const [filteredTools, isLoading] = getTools(tools || [])
  const { t } = useTranslation('tools')

  const toolsAvailable = filteredTools && filteredTools?.length > 0

  const getUseCases = (tool: Tool) => {
    return tool.siteCount && <Use>{t('usedBy', { count: tool.siteCount })}</Use>
  }

  return (
    <>
      <TitleWrap>
        <Title>Tools</Title>
      </TitleWrap>
      <SearchAndFilterContainer>
        <SearchAndFilterWrap>
          <Search withPagination={false} />
          <Filters filters={filters} withPagination={false} />
        </SearchAndFilterWrap>
      </SearchAndFilterContainer>
      <Container>
        <Divider />
      </Container>
      <Container>
        {toolsAvailable && (
          <OtherTools>
            <OtherToolsWrap>
              {isLoading
                ? [...Array(6)].map((el, index) => (
                    <ToolCardSkeleton key={index} />
                  ))
                : filteredTools?.map((tool, index) => (
                    <ToolCard
                      key={`${tool.name}-${index}`}
                      href={tool.path}
                      appearance="grid"
                    >
                      {tool.avatar && (
                        <ToolImage>
                          {tool.avatar && (
                            <Media
                              layout="fill"
                              hardcropRatio="square"
                              hardHotspot={[0, 0]}
                              sizes={
                                '(min-width: 1000px) 33vw ,(min-width: 740px) 50vw , 100vw'
                              }
                              {...tool.avatar}
                            />
                          )}
                        </ToolImage>
                      )}

                      <ToolInfo noAvatar={!tool.avatar}>
                        <ToolName>{tool.name}</ToolName>
                        <ToolCategoryAndUseCaseContainer>
                          <ToolCategory>{tool.toolCategory?.name}</ToolCategory>
                          <UseCasesMobile>{getUseCases(tool)}</UseCasesMobile>
                        </ToolCategoryAndUseCaseContainer>
                      </ToolInfo>
                      <UseCasesDesktop>{getUseCases(tool)}</UseCasesDesktop>
                    </ToolCard>
                  ))}
            </OtherToolsWrap>
          </OtherTools>
        )}
        {!isLoading && !toolsAvailable && (
          <NoResults>
            {t('noResults')}
            <Link href="/tools" appearance="outline">
              {t('clearFilters')}
            </Link>
          </NoResults>
        )}
      </Container>
    </>
  )
}
