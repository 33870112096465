import { FC } from 'react'
import dynamic from 'next/dynamic'

import { PageBuilderSection } from '@the-headless-club/models'
import { SectionSiteList } from './SectionSiteList/SectionSiteList'
import { SectionToolsLandingPage } from './SectionToolsLandingPage/SectionToolsLandingPage'
import { SectionHeroLanding } from './SectionHeroLanding/SectionHeroLanding'
import { SectionSiteDetails } from './SectionSiteDetails/SectionSiteDetails'
import { SectionInfoAboutUs } from './SectionInfoAboutUs/SectionInfoAboutUs'
import { SectionHeroPromoText } from './SectionHeroPromoText/SectionHeroPromoText'

// @ts-ignore
const DynamicSectionBaseSiteList = dynamic(() =>
  import('./SectionBaseSiteList/SectionBaseSiteList').then(
    (mod) => mod.SectionBaseSiteList,
  ),
)

// @ts-ignore
const DynamicSectionRichText = dynamic(() =>
  import('./SectionRichText/SectionRichText').then(
    (mod) => mod.SectionRichText,
  ),
)

export type PageBuilderProps = {
  section: PageBuilderSection
}

export const PageBuilder: FC<PageBuilderProps> = ({ section }) => {
  /**
   * We use two section import strategies:
   * - Dynamic - in order to minimise the size of bundle chunks, we prefer to import sections dynamically
   * - Static - to prevent the "blink" effect in most exposed sections we use static imports
   */
  switch (section._type) {
    // dynamic sections ======================================

    case 'sectionRichText':
      return <DynamicSectionRichText {...section} />

    case 'sectionBaseSiteList':
      return <DynamicSectionBaseSiteList {...section} />

    // static sections ======================================
    case 'sectionHeroPromoText':
      return <SectionHeroPromoText {...section} />

    case 'sectionInfoAboutUs':
      return <SectionInfoAboutUs {...section} />

    case 'sectionSiteList':
      return <SectionSiteList {...section} />
    case 'sectionToolsLandingPage':
      return <SectionToolsLandingPage {...section} />
    case 'sectionHeroLanding':
      return <SectionHeroLanding {...section} />
    case 'sectionSiteDetails':
      return <SectionSiteDetails {...section} />

    default:
      return null
  }
}
