import {
  Container,
  Link,
  SiteCard,
  SiteCardSkeleton,
} from '@the-headless-club/components'
import { SectionSiteListProps } from '@the-headless-club/models'
import { getSites } from '@the-headless-club/services/sanity-service'

import { styled } from '@the-headless-club/styles'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Filters } from './Filters'
import { usePagination } from './helpers/usePagination'
import { Pagination } from './Pagination'
import { Search } from './Search'

const Wrap = styled('div', {
  background: '$alabaster',
  paddingBottom: '$13',
  '@lg': {
    paddingBottom: '$13',
  },
})
const SearchAndFilterContainer = styled('div', {
  display: 'flex',
  overflowX: 'scroll',
  overflowY: 'auto',
  '@lg': {
    containerSpace: 'medium',
    overflow: 'unset',
    paddingTop: '$7',
  },
})
const SearchAndFilterWrap = styled('div', {
  paddingTop: '$10',
  display: 'flex',
  width: '100%',
  overflowY: 'auto',
  justifyContent: 'space-between',
  paddingBottom: '$5',
  paddingLeft: 20,
  containerSpace: 'medium',
  alignItems: 'center',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '@lg': {
    overflow: 'unset',
    containerSpace: 'none',
  },
})
const StyledContainer = styled(Container, {
  background: '$alabaster',
  display: 'grid',
  rowGap: '$12',
  '@md': {
    gridTemplateColumns: '1fr 1fr ',
    rowGap: '$12',
    columnGap: '$9',
  },
  '@lg': {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
})

const Divider = styled(Container, {
  height: 1,
  background: '$almost_black',
  marginBottom: '$10',
})

const NoResults = styled('div', {
  textAlign: 'center',
  gridColumn: '1/4',
  projectFont: 'body04',
  display: 'grid',
  gridAutoFlow: 'row',
  gap: '$8',
  justifyContent: 'center',
})

export const SectionSiteList: FC<SectionSiteListProps> = ({
  items,
  filters,
  start,
  limit,
  totalCount: initialTotalCount,
  priority,
}) => {
  const { t } = useTranslation('sites')
  const [currentPage] = usePagination()

  const [sites, totalCount, isLoading] = getSites(
    items || [],
    limit,
    initialTotalCount,
    currentPage,
  )

  useEffect(() => {
    const scrollPosition = sessionStorage.getItem('scrollPosition')
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition))
      sessionStorage.removeItem('scrollPosition')
    }
  }, [])

  const skeletons = [...Array(6)]

  const sitesAvailable = sites && sites?.length > 0

  const PRIORITY_SITES_AMOUNT = 3

  return (
    <>
      <SearchAndFilterContainer>
        <SearchAndFilterWrap>
          <Search />
          <Filters filters={filters} />
        </SearchAndFilterWrap>
      </SearchAndFilterContainer>
      <Container>
        <Divider />
      </Container>
      <Wrap>
        <StyledContainer>
          {isLoading &&
            skeletons.map((el, index) => <SiteCardSkeleton key={index} />)}

          {!isLoading &&
            sitesAvailable &&
            sites?.map((item, index) => (
              <SiteCard
                priority={!!priority && index < PRIORITY_SITES_AMOUNT}
                saveScroll
                item={item}
                key={`${item.name}-${index}`}
              />
            ))}

          {!isLoading && !sitesAvailable && (
            <NoResults>
              {t('noResults')}
              <Link href="/" appearance="outline">
                {t('clearFilters')}
              </Link>
            </NoResults>
          )}
        </StyledContainer>
      </Wrap>
      <Container>
        <Pagination start={start} totalCount={totalCount} limit={limit} />
      </Container>
    </>
  )
}
