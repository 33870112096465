import { FC } from 'react'
import { SectionHeroPromoTextProps } from '@the-headless-club/models'
import { Container, PortableText } from '@the-headless-club/components'
import { styled } from '@the-headless-club/styles'

const StyledContainer = styled(Container, {
  backgroundColor: '$alabaster',
  paddingTop: '$11',
  paddingBottom: '$11',
})
const Wrap = styled('div', {
  maxWidth: '90ch',
  '.highlighted': {
    color: '$blue',
  },
  a: {
    textDecoration: 'underline',
    textUnderlineOffset: '1px',
    opacity: 0.8,
    projectFont: 'heading02',
    verticalAlign: '4px',
    whiteSpace: 'nowrap',
    marginLeft: '3px',
    '@lg': {
      verticalAlign: '5px',
    },
    '&:hover': {
      opacity: '0.5',
    },
  },
  'h1, h2, h3, p, ul, ol': {
    marginBottom: '$9',
    marginTop: '$9',
    projectFont: 'heading01',
  },
  'p, ul, ol': {
    listStyle: 'inside',
  },
  'ol > li': {
    listStyleType: 'decimal',
  },
  'ul > li': {
    listStyleType: 'disc',
  },
})

export const SectionHeroPromoText: FC<SectionHeroPromoTextProps> = ({
  text,
}) => {
  return (
    <StyledContainer>
      <Wrap>
        <PortableText withStyles={false} value={text}></PortableText>
      </Wrap>
    </StyledContainer>
  )
}
