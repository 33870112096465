import { FC } from 'react'
import { SectionHeroLandingProps } from '@the-headless-club/models'
import { styled } from '@the-headless-club/styles'
import { Container, Link, Media } from '@the-headless-club/components'
import { GoToArrow } from '@the-headless-club/icons'
import { useTranslation } from 'react-i18next'
import { ROUTE_BASE_TOOLS_LANDING_PAGE } from '@the-headless-club/configs'

const Wrap = styled('div', {
  backgroundColor: '$alabaster',
  paddingTop: '$13',
  paddingBottom: '$12',
  '@lg': {
    paddingBottom: '$8',
  },
})

const StyledContainer = styled(Container, {
  justifyContent: 'center',
  display: 'grid',
  justifyItems: 'center',
  gridGap: '$8',
})
const Title = styled('div', {
  justifySelf: 'center',
  projectFont: 'heading01',
})
const MediaWrap = styled('div', {
  borderRadius: '$rMax',
  overflow: 'hidden',
  width: 100,
  height: 100,
})
const AdditionalInfo = styled('div', {
  display: 'flex',
  gap: '$6',
  alignItems: 'center',
  justifyContent: 'center',
  projectFont: 'body02',
})
const MainInfo = styled('div', {
  display: 'grid',
  gap: '$6',
})
const ToolLink = styled(Link, {
  textDecoration: 'underline',
})
const ToolLinkWrap = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$3',
})
const Dot = styled('div', {
  background: '$almost_black',
  width: '$2',
  height: '$2',
  justifyContent: 'center',
  alingItems: 'center',
})

export const SectionHeroLanding: FC<SectionHeroLandingProps> = ({
  title,
  toolCategory,
  avatar,
  totalCount,
  url,
}) => {
  const { t } = useTranslation('sites')
  return (
    <Wrap>
      <StyledContainer>
        {avatar && (
          <MediaWrap>
            <Media {...avatar} />
          </MediaWrap>
        )}
        <MainInfo>
          <Title>{title}</Title>
          <AdditionalInfo>
            {totalCount ? (
              <div>
                {t(totalCount > 1 ? 'stores' : 'store', { value: totalCount })}
              </div>
            ) : (
              <></>
            )}
            {!!totalCount && url && <Dot />}
            {url && (
              <ToolLinkWrap>
                <ToolLink appearance="grid" href={url}>
                  {t('website')}
                </ToolLink>
                <GoToArrow />
              </ToolLinkWrap>
            )}
          </AdditionalInfo>
        </MainInfo>

        {toolCategory && (
          <Link
            appearance="containedWhiteRect"
            href={
              ROUTE_BASE_TOOLS_LANDING_PAGE + '?Category=' + toolCategory.name
            }
          >
            {toolCategory?.name}
          </Link>
        )}
      </StyledContainer>
    </Wrap>
  )
}
