import { SearchIcon } from '@the-headless-club/icons'
import { styled } from '@the-headless-club/styles'
import { useRouter } from 'next/router'
import { FC, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import debounce from 'lodash.debounce'

const SearchWrap = styled('div', {
  background: 'none',
  border: 'none',
  display: 'grid',
  gridAutoFlow: 'column',
  alignItems: 'center',
  gridTemplateColumns: 'auto 1fr',
  justifyContent: 'flex-start',
  gap: '$5',
  width: '100%',
  padding: 0,
})
const SearchInput = styled('input', {
  background: 'none',
  border: 'none',
  projectFont: 'body04',
  color: '$almost_black',
  width: 0,
  transition: 'width 0.2s',
  visibility: 'hidden',
  padding: 0,
  variants: {
    isVisible: {
      true: {
        width: 175,
        visibility: 'visible',
      },
    },
  },
  '@lg': {
    width: 'auto',
    visibility: 'visible',
  },
})

const SearchIconWrap = styled('button', {
  background: 'none',
  padding: 0,
  margin: 0,
})

type SearchProps = {
  withPagination?: boolean
}

export const Search: FC<SearchProps> = () => {
  const { t } = useTranslation('search')
  const { query, replace } = useRouter()
  const [isInputVisible, setIsInputVisible] = useState(false)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const debouncedChangeHandler = useCallback(
    debounce((e) => {
      const { page, ...restOfQuery } = query
      const value = e.target.value
      replace(
        {
          query: {
            ...restOfQuery,
            ...(value?.length > 0 && { v: value }),
          },
        },
        undefined,
        {
          shallow: true,
        },
      )
    }, 500),
    [],
  )

  const handleIconClick = () => {
    setIsInputVisible(true)
    const input = inputRef.current as HTMLInputElement
    input.focus()
  }
  return (
    <SearchWrap>
      <SearchIconWrap onClick={handleIconClick} as={'button'}>
        <SearchIcon />
      </SearchIconWrap>
      <SearchInput
        ref={inputRef}
        type="search"
        isVisible={isInputVisible}
        enterKeyHint="search"
        onChange={debouncedChangeHandler}
        autoComplete="false"
        placeholder={t('typeToSearch')}
      ></SearchInput>
    </SearchWrap>
  )
}
