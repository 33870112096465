import { styled } from '@the-headless-club/styles'
import { FC, HTMLProps, KeyboardEvent, MutableRefObject, useRef } from 'react'

const CheckIndicator = styled('div', {
  borderRadius: '$rMax',
  width: '$5',
  height: '$5',
  background: '$alabaster',
})

const FilterInputWrap = styled('label', {
  width: '100%',
  background: 'none',
  border: 'none',
  padding: '$8',
  color: '$alabaster',
  zIndex: '$content',
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '$8',
  textAlign: 'center',
  paddingRight: '$11',
  height: '$11',
  '@lg': {
    '&:hover': {
      opacity: 0.5,
    },
  },

  '&:focus-visible': {
    borderRadius: '$r2  ',
    outline: '1px solid $alabaster',
  },
  variants: {
    isSelected: {
      true: {
        padding: '$8 $9 $8 $8',
      },
    },
  },
})

const StyledInput = styled('input', {
  display: 'none',
  visibility: 'hidden',
  opacity: 0,
  position: 'absolute',
  left: -9999,
})

type FilterInputProps = {
  filterGroupName: string
  value: string
  getItemProps: (userProps?: HTMLProps<HTMLElement>) => any
  tabIndex: 0 | 1
  listRef: MutableRefObject<(HTMLLabelElement | null)[]>
  index: number
  handleChange: () => void
  checked: boolean
}

export const FilterInput: FC<FilterInputProps> = ({
  filterGroupName,
  value,
  checked,
  handleChange,
  getItemProps,
  tabIndex,
  index,
  listRef,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  function handleKeyDown(event: KeyboardEvent) {
    event.preventDefault()
    if (event.key === ' ') {
      inputRef?.current?.click()
    }
  }

  return (
    <FilterInputWrap
      ref={(node) => (listRef.current[index] = node)}
      tabIndex={tabIndex}
      onKey
      isSelected={checked}
      {...getItemProps({ onKeyDown: handleKeyDown })}
    >
      <span>{value}</span>
      {checked && <CheckIndicator />}
      <StyledInput
        type={'checkbox'}
        name={filterGroupName}
        value={value}
        onChange={handleChange}
        checked={checked}
        ref={inputRef}
      />
    </FilterInputWrap>
  )
}
