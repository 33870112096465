import { Filter } from '@the-headless-club/models'

import { styled } from '@the-headless-club/styles'
import { FC } from 'react'
import { FilterDropdown } from './FilterDropdown'
import { FilterPerformanceToggle } from './FilterPerformanceToggle'

const FiltersWrap = styled('div', {
  display: 'flex',
  gap: '$4',
  justifyContent: 'flex-end',
})

type FiltersProps = {
  filters?: Filter[]
  withPagination?: boolean
}

export const Filters: FC<FiltersProps> = ({
  filters,
  withPagination = true,
}) => {
  return (
    <FiltersWrap>
      {filters?.map((filter, index) => {
        if (filter.type === 'dropdown') {
          return (
            <FilterDropdown
              key={`${filter.name}-${index}`}
              filter={filter}
              withPagination={withPagination}
            />
          )
        }

        if (filter.type === 'performance-toggle') {
          return (
            <FilterPerformanceToggle
              key={`${filter.name}-${index}`}
              filter={filter}
              withPagination={withPagination}
            />
          )
        }

        return null
      })}
    </FiltersWrap>
  )
}
