import { FC, useRef, useState } from 'react'
import {
  offset,
  flip,
  size,
  autoUpdate,
  useFloating,
  useInteractions,
  useRole,
  useDismiss,
  useClick,
  shift,
  useListNavigation,
  FloatingFocusManager,
  useFocus,
} from '@floating-ui/react-dom-interactions'
import { styled } from '@the-headless-club/styles'
import { StyledClickable } from '@the-headless-club/components'
import { ArrowDown } from '@the-headless-club/icons'
import { Filter } from '@the-headless-club/models'
import { useRouter } from 'next/dist/client/router'
import { FilterInput } from './FilterInput'

const Wrap = styled('div', {
  background: '$almost_black',
  overflowY: 'auto',
  padding: '$2',
  boxSizing: 'border-box',
  userSelect: 'none',
  borderRadius: '$r2',
  zIndex: '$header',
})
const FilterButton = styled(StyledClickable, {
  position: 'relative',
  display: 'grid',
  gap: '$5',
  projectFont: 'body01',
  color: '$almost_black',
  gridAutoFlow: 'column',
  alignItems: 'baseline',
  whiteSpace: 'nowrap',
  '&:hover': {
    opacity: 0.5,
  },
  paddingLeft: 34,
  variants: {
    hasItems: {
      true: {
        paddingLeft: '$4',
      },
    },
  },
})

const FormWrapper = styled('form', {
  display: 'grid',
  projectFont: 'body01',
  gridAutoFlow: 'row',
  borderRadius: '$r2',
  background: '$almost_black',
  padding: '$4',
})
const Dot = styled('div', {
  width: 4,
  height: 4,
  background: '$almost_black',
  borderRadius: '$rMax',
  marginRight: '$5',
})

const ItemsCount = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'center',
})

interface FilterDropdownProps {
  filter: Filter
  withPagination?: boolean
}

export const FilterDropdown: FC<FilterDropdownProps> = ({ filter }) => {
  const [open, setOpen] = useState(false)
  const formRef = useRef<HTMLFormElement>(null)
  const listItemsRef = useRef<Array<HTMLLabelElement | null>>([])
  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  const filterValues = filter.values

  const otherFilterIndex = filterValues.findIndex(
    (el) => el?.name?.toLowerCase() === 'other',
  )

  if (otherFilterIndex) {
    filterValues.push(filterValues.splice(otherFilterIndex, 1)[0])
  }

  const { query, replace } = useRouter()
  const groupValues = query[filter.name]
  const selectedFilterValuesArray = groupValues
    ? Array.isArray(groupValues)
      ? groupValues
      : [groupValues]
    : []

  const {
    x,
    y,
    reference: buttonReference,
    floating,
    strategy,
    context,
  } = useFloating({
    placement: 'bottom',
    open,
    onOpenChange: setOpen,
    middleware: [
      offset(10),
      flip(),
      shift({ padding: 10 }),
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            minWidth: `${rects.reference.width}px`,
            maxHeight: `${availableHeight}px`,
          })
        },
        padding: 10,
      }),
    ],
    whileElementsMounted: autoUpdate,
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [
      useClick(context),
      useFocus(context),
      useRole(context, { role: 'listbox' }),
      useDismiss(context),
      useListNavigation(context, {
        listRef: listItemsRef,
        activeIndex,
        openOnArrowKeyDown: true,
        onNavigate: setActiveIndex,
      }),
    ],
  )

  const onFormSubmit = () => {
    const form = formRef.current || undefined
    const data = new FormData(form)

    const { page, ...strippedQuery } = query
    const filterValues = Array.from(data.getAll(filter.name)) as string[]

    if (filterValues.length === 0) {
      delete strippedQuery[filter.name]
    }

    replace(
      {
        query: {
          ...strippedQuery,
          ...(filterValues.length > 0 && { [filter.name]: filterValues }),
        },
      },
      undefined,
      {
        shallow: true,
      },
    )
  }

  return (
    <>
      <FilterButton
        appearance="grid"
        {...getReferenceProps({
          onKeyDown(e) {
            if (e.key === 'Tab') {
              setOpen(false)
            }
          },
        })}
        ref={buttonReference}
        hasItems={selectedFilterValuesArray.length > 0}
      >
        <span>{filter.label}</span>
        {selectedFilterValuesArray.length > 0 && (
          <ItemsCount>
            <Dot />
            <span>{selectedFilterValuesArray?.length}</span>
          </ItemsCount>
        )}
        <ArrowDown />
      </FilterButton>
      {open && (
        <FloatingFocusManager
          context={context}
          order={['reference', 'content']}
          returnFocus={false}
        >
          <Wrap
            {...getFloatingProps({
              ref: floating,
              style: {
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                overflow: 'auto',
              },
              onKeyDown(e) {
                if (e.key === 'Tab') {
                  setOpen(false)
                }
              },
            })}
          >
            <FormWrapper ref={formRef}>
              {filterValues?.map((item, index) => {
                return (
                  item.name && (
                    <FilterInput
                      filterGroupName={filter.name}
                      key={`${item.name}-${index}`}
                      value={item.name}
                      checked={selectedFilterValuesArray?.includes(item.name)}
                      handleChange={() => onFormSubmit()}
                      tabIndex={activeIndex === index ? 0 : 1}
                      getItemProps={getItemProps}
                      listRef={listItemsRef}
                      index={index}
                    />
                  )
                )
              })}
            </FormWrapper>
          </Wrap>
        </FloatingFocusManager>
      )}
    </>
  )
}
