import { useRouter } from 'next/dist/client/router'

export const usePagination = () => {
  const { query, replace } = useRouter()

  const currentPage = (() => {
    if (!query?.page) {
      return 1
    }

    if (Array.isArray(query.page)) {
      return parseInt(query.page[0]) || 1
    }

    return parseInt(query.page)
  })()

  const setCurrentPage = (index: number) => {
    replace(
      {
        query: {
          ...query,
          page: index,
        },
      },
      undefined,
      {
        shallow: true,
        scroll: true,
      },
    )
  }
  return [currentPage, setCurrentPage] as const
}
